@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/transitions";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

body {
  --bs-body-font-family: Inter, sans-serif;
  padding: 3rem 0;

  @include media-breakpoint-up(md) {
    padding: 4rem 0;
  }
}

.logo {
  height: 64px;
}

.application-processes {
  counter-reset: process-list;
  list-style: none;
  padding: 0;
}

.application-process {
  position: relative;
  padding: 1.5rem 0 1.5rem 4rem;
  border-bottom: 1px solid $border-color;

  &::before {
    counter-increment: process-list;
    content: counter(process-list);
    position: absolute;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    background: $blue;
    border-radius: 100%;
    font-size: 1rem;
    text-align: center;
    color: $white;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__timeline {
    color: $secondary;
  }

  &__description:last-child {
    margin: 0;
  }
}

.accordion-flush .accordion-item--learning-path {
  border-bottom-left-radius: var(--bs-card-border-radius);
  border-bottom-right-radius: var(--bs-card-border-radius);
}
